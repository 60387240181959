.header {
    border-bottom: 1px solid #c7c7c7;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 100;
}
.headerInner {

}

.burger {
    padding: 5px;

    cursor: pointer;
    transition: 0.3s;
}

.burger:hover {
    background-color: #e8e8e8;
    transition: 0.3s;
}
