.footer {
    border-top: 1px solid #c7c7c7;
    background-color: white;
    width: 100%;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footerInner {

}